<template lang='pug'>
.comip
    v-row.mb-2
        v-card.mb-5(min-width="50%")
            v-card-title(left)
                .text-h6 Extra IP Addresses
            v-card-text
                v-form.ml-4(
                    ref='form'
                    v-model='isValid'
                    lazy-validation
                    )
                    v-row(v-for='(item, index) in addressMap', :key='index')
                        v-row
                            v-col(cols='4')
                                v-text-field(
                                    type='text'
                                    label=`Name`
                                    v-model.trim='addressMap[index].name'
                                    :rules="[rules.required, rules.duplicateName]"
                                    color='brand'
                                    @input="onChange"
                                )
                            v-col(cols='4')
                                v-text-field(
                                    type='text'
                                    label='Address'
                                    v-model.trim='addressMap[index].address'
                                    :rules="[rules.required, rules.validAddress]"
                                    color='brand'
                                    placeholder='0.0.0.0'
                                    @input="onChange"
                                )
                            v-col(cols='2')
                                v-icon( @click="deleteItem(index)" color="red") delete
            v-card-actions
                v-btn.brand.white--text(:disabled="!isValid" @click="addItem") Add Extra IP Address

</template>

<script lang="js">
import ipRegex  from 'ip-regex';

export default {
    name: "comip",
    props: {
        device: {
            type: Object,
        },
        updateCommDevice: {
            type: Function,
            required: true
        }
    },
    emits: ['formValid'],
    watch: {
        isValid (val){
            this.$emit('formValid', val);
        }
    },
    methods: {
        /**
         * add item (default) to collection
         */
        addItem () {
            this.isValid = false;
            this.addressMap.push({...this.defaultAddress});
            this.$nextTick(() => {
                if(this.$refs && this.$refs.form) this.$refs.form.validate();
            });
        },

        /**
         * Update the device
         */
        onChange () {
            this.isValid = this.$refs.form.validate();

            if(this.isValid){
                const others = this.addressMap.filter(item => item.name && item.address);
                this.updateCommDevice({
                    id: this.device.id,
                    otherAddresses: others.length ? JSON.stringify(others) : ''
                });
            }
        },

        /**
         * delete item from collection
         * @param {number} index
         */
        deleteItem (index) {
            this.addressMap.splice(index, 1);
            this.isValid = false;
            if(this.$refs && this.$refs.form) this.$refs.form.validate();
            this.onChange();

        },

        /**
         * checks if name exists more than once
         *  since the name already in the field is part of the collection
         * @param {string} name
         */
        checkNameExists (name) {
            return this.addressMap.filter(a => a.name.toLowerCase() === name.toLowerCase()).length > 1;
        },

        init () {
            this.addressMap = [];

            let rawOthers = this.device.otherAddresses || "" ;
            if(rawOthers.length > 0){
                let others = JSON.parse(rawOthers);
                others.filter(item => item.name && item.address).forEach(({name, address}) => {
                    this.addressMap.push({name, address});
                });
            }
            this.$nextTick(() => {
                if(this.$refs && this.$refs.form) this.$refs.form.validate();
                this.$emit('formValid', this.isValid);
            });
        }
    },
    data () {
        return {
            isValid: true,
            /** @type {{name:string, address:string}[]} */
            addressMap:[],
            defaultAddress: {
                name: '',
                address: ''
            },
            rules: {
                required: value => !!value || 'Required.',
                duplicateName: value => !this.checkNameExists(value) || 'Name already exists',
                validAddress: (value) => {
                    return ipRegex({exact: true}).test(value) || 'Invalid IP address';
                },
            }
        };
    },
    created () {
        this.init();

    },
};
</script>
