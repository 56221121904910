<template lang="pug">
.commsManager
    v-card.ml-8(light)
        v-card-title.brand.white--text.py-2 Comm Devices
        v-card-subtitle
            v-text-field(
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                color="brand",
                clearable, clear-icon='clear'
            )
        v-card-text
            v-data-table(
                :headers="headers"
                :items="localDevices"
                :search="search"
                item-key="name"
                sort-by="name"
                dense
                @click:row='editItem'
            )
                template(v-slot:item.notes="{ item }")
                    v-icon(v-if='item.notes') note
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='dialog'
                            max-width='900px'
                            light
                        )
                            template(v-slot:activator="{on, attrs}")
                                v-btn.brand.white--text(
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    ref="editButton"
                                ) New Device

                            v-card
                                v-card-title.brand.white--text {{ formTitle }}
                                v-card-text
                                    v-container
                                        v-form( ref="form" v-model="valid" lazy-validation)
                                            //- ALL
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label="Name"
                                                        v-model.trim='editedItem.name'
                                                        :rules="[rules.required, rules.uniqueName]"
                                                        outlined, dense, color='brand'
                                                    )
                                                v-col
                                                    v-select(
                                                        v-if="editedIndex === -1 || typeof editedItem.commType !== 'string' || editedItem.commType === 'cell'"
                                                        label='Device Type',
                                                        v-model='editedItem.commType'
                                                        :items='commTypes'
                                                        item-text='name'
                                                        item-value='value'
                                                        :rules="[rules.required, rules.notLegacy]"
                                                        outlined, dense, color='brand'
                                                    )
                                                    v-select(
                                                        v-else
                                                        label='Device Type',
                                                        v-model='editedItem.commType'
                                                        :items='commTypes'
                                                        item-text='name'
                                                        item-value='value'
                                                        readonly
                                                        :rules="[rules.required, rules.notLegacy]"
                                                        outlined, dense, color='brand'
                                                    )

                                            //- radio router
                                            v-row(v-if="['router', 'radio'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(:rules='[rules.required]', outlined, dense, label='BACnet Port', v-model.trim='editedItem.bacnetPort', color='brand', type='number', hide-details)
                                                v-col
                                                    v-text-field(:rules="rules.ipRules.optional", outlined, dense, label='Gateway Address', v-model='editedItem.gatewayAddress', color='brand')
                                                v-col
                                                    v-text-field(:rules="rules.ipRules.optional", outlined, dense, label='IP Address', v-model='editedItem.ipAddress', color='brand')

                                            //- cell modem common
                                            v-row(v-if="['cell customer', 'cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='APN', v-model.trim='editedItem.apn', color='brand', type='text', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='IMEI', v-model.trim='editedItem.imei', color='brand', type='text', hide-details)
                                            v-row(v-if="['cell customer', 'cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Job Number', v-model.trim='editedItem.jobNumber', color='brand', type='text', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='SIMS', v-model.trim='editedItem.simsCard', color='brand', type='text',  maxlength='30', hide-details)
                                            v-row(v-if="['cell customer', 'cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-select(
                                                        outlined, dense, label='PW Updated',
                                                        v-model='editedItem.passwordUpdated',
                                                        :items='yesNo', item-text='name', item-value='value', color='brand', hide-details
                                                    )
                                                v-col
                                                    v-text-field(outlined, dense, label='PW Initials', v-model.trim='editedItem.passwordDateInitials', color='brand', type='text', hide-details)
                                            v-row(v-if="['cell customer', 'cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Phone #', v-model.trim='editedItem.phoneNumber', color='brand', type='tel', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='Serial #', v-model.trim='editedItem.serialNumber', color='brand', type='text', hide-details)


                                            //- cell modem customer
                                            v-row(v-if="['cell customer'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Brand', v-model='editedItem.modemBrand', color='brand', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='Firmware', v-model='editedItem.firmware', color='brand', hide-details)
                                            v-row(v-if="['cell customer'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Site', v-model='editedItem.customerSite', color='brand', hide-details)
                                                v-col
                                                    datepicker(v-model='editedItem.dateProgrammed', label='Programmed', outlined, dense)
                                                v-col
                                                    v-text-field(outlined, dense, label='Monthly Data (MB)', v-model='editedItem.monthlyData', color='brand', hide-details, type="number", min="0")
                                            v-row(v-if="['cell customer'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Phys Address', v-model='editedItem.physicalAddress', color='brand', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='State', v-model='editedItem.state', color='brand', hide-details)
                                            v-row(v-if="['cell customer'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Site Name', v-model='editedItem.siteName', color='brand', hide-details)

                                            //- cell_modem_dc_public
                                            v-row(v-if="['cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-select(
                                                        outlined, dense, label='Block Texting',
                                                        v-model='editedItem.blockTexting',
                                                        :items='yesNo', item-text='name', item-value='value', color='brand', hide-details
                                                    )
                                                v-col
                                                    v-select(
                                                        outlined, dense, label='Unrestricted IP',
                                                        v-model='editedItem.unrestrictedIP',
                                                        :items='yesNo', item-text='name', item-value='value', color='brand', hide-details
                                                    )
                                            v-row(v-if="['cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    datepicker(v-model='editedItem.cancelDate', label='Canceled', outlined, dense)
                                                v-col
                                                    datepicker(v-model='editedItem.deactivationDate', label='Deactivated', outlined, dense)
                                            v-row(v-if="['cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Price Plan', v-model='editedItem.pricePlanID', color='brand', hide-details)

                                            //- cell_modem_dc_private
                                            v-row(v-if="['cell dc private'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='IP Class', v-model='editedItem.ipClass', color='brand', hide-details)
                                                v-col
                                                    v-text-field(:rules="rules.ipRules.optional", outlined, dense, label='IP Local Assigned', v-model='editedItem.ipLocalAssigned', color='brand', hide-details)
                                            v-row(v-if="['cell dc private'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(:rules="rules.ipRules.optional", outlined, dense, label='IP Local Static', v-model='editedItem.ipLocalStatic', color='brand', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='Wireless #', v-model='editedItem.wirelessNumber', color='brand', hide-details, type="tel")

                                            //- cell dc all
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Acct #', v-model='editedItem.accountNumber', color='brand', hide-details)
                                                v-col
                                                    datepicker(v-model='editedItem.activationDate', label='Activated', outlined, dense)
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Cost Ctr', v-model='editedItem.costCenter', color='brand', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='Coverage', v-model='editedItem.coverage', color='brand', hide-details)
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Data Plan', v-model='editedItem.dataPlan', color='brand', hide-details)
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Model', v-model='editedItem.model', color='brand', hide-details)
                                                v-col
                                                    v-text-field(outlined, dense, label='Device ID', v-model='editedItem.deviceID', color='brand', hide-details)
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='Password', v-model='editedItem.password', color='brand', hide-details)
                                                v-col
                                                    datepicker(v-model='editedItem.passwordDate', label='PW Date', outlined, dense)
                                            v-row(v-if="['cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(outlined, dense, label='User Name', v-model='editedItem.username', color='brand', hide-details)

                                            //- cell_modem_dc_public_customer
                                            v-row(v-if="['cell dc public', 'cell customer'].includes(editedItem.commType)")
                                                v-col
                                                    v-text-field(
                                                        label='Static IP',
                                                        v-model='editedItem.ipStatic'
                                                        :rules="rules.ipRules.optional",
                                                        outlined, dense, color='brand'
                                                    )

                                            //- all cell modems can add extra IP addresses
                                            v-row(v-if="['cell customer', 'cell dc private', 'cell dc public'].includes(editedItem.commType)")
                                                v-col
                                                    v-container
                                                        comip(
                                                            :device='editedItem',
                                                            :updateCommDevice='updateCommDeviceIpAddresses',
                                                            @formValid="onIpValidChange"
                                                            color='brand'
                                                            ref="comip"
                                                        )
                                            //- credentials
                                            v-row(v-if="typeof editedItem.commType === 'string' && editedItem.commType !== 'cell'")
                                                v-col
                                                   credentials(
                                                        :customersCredentials = 'credentials.filter(c => c.customersCommId === editedItem.id)',
                                                        :deviceId='editedItem.id',
                                                        :deviceType="localDeviceType",
                                                        :customerId="customerId"
                                                        @delete="onDeleteCredential",
                                                        @update="onUpdateCredential",
                                                        @formValid="onCredValidChange"
                                                        color='brand'
                                                        ref="creds"
                                                    )
                                            v-row(v-if="typeof editedItem.commType === 'string' && editedItem.commType !== 'cell'")
                                                v-col
                                                    .text-h6 Notes
                                                    VueTrix.notesEditor(
                                                        v-model='editedItem.notes',
                                                        placeholder='Notes'
                                                    )
                                        //- End Form

                                v-card-actions
                                    v-btn.actions.red.white--text(
                                        text
                                        :disabled="!allValid"
                                        @click="dialogDelete = true"
                                    ) Delete
                                    v-spacer
                                    v-btn.actions.orange.white--text(
                                        text
                                        @click="close"
                                    ) Cancel
                                    v-btn.actions.brand.white--text(
                                        text
                                        :disabled="!allValid"
                                        @click="save"
                                    ) Save
                        v-dialog(
                            v-model='dialogDelete'
                            max-width='500px'
                            light
                        )
                            v-card
                                v-card-title.brand.white--text Confirm Delete
                                v-card-text
                                    span.text-h6 Are you sure you want to delete this device?
                                v-card-actions
                                    v-spacer
                                    v-btn.orange.white--text(
                                        text
                                        @click="closeDelete"
                                    ) Cancel
                                    v-btn.brand.white--text(
                                        text
                                        @click="deleteItemConfirm"
                                    ) OK
                template(v-slot:item.types="{ item }")
                    span {{ item.commTypeName }}
                        v-icon.ml-2(v-if="!validCommTypes.includes(item.commType)", color="red") error
                template(v-slot:item.credentials='{item}')
                    span {{ credentialsPerDevice(item.id) }}
                template(v-slot:no-data)
                    v-btn(
                        color="primary"
                        @click="initialize"
                    ) Reset
</template>

<script>

import dti from 'dti';
import ipRegex from 'ip-regex';
import VueTrix from 'vue-trix';
import comip from '@/components/common/comip';
import datepicker from '@/components/common/datepicker';
import credentials from '@/components/common/credentials';

export default {
    name:'commsManager',
    components: {
        comip,
        VueTrix,
        datepicker,
        credentials
    },
    props :{
        updateComms: {
            type: Function,
            required: true
        },
        credentials: {
            type: Array,
            required: true
        },
        devices: {
            type: Array,
            required: true
        },
        customerId: {
            type: Number,
            required: true
        },
        jiggle: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            localDeviceType: 'comm',
            localCredentials: [],
            localDevices: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
            },
            commTypes: [
                {
                    name: 'Ethernet Radio',
                    value: 'radio'
                },
                {
                    name: 'Cell Modem: Customer',
                    value: 'cell customer'
                },
                {
                    name: 'Cell Modem: DC Private',
                    value: 'cell dc private'
                },
                {
                    name: 'Cell Modem: DC Public',
                    value: 'cell dc public'
                },
                {
                    name: 'Router',
                    value: 'router'
                },
            ],
            defaultDevice: {
                add: false,
                edit: false,
                // CELL_MODEM_DC_PUBLIC_CUSTOMER
                ipStatic: null,
                // CELL_MODEM_CUSTOMER
                customerSite: null,
                dateProgrammed: null,
                deviceID3G: null,
                deviceID4G: null,
                firmware: null,
                modemBrand: null,
                monthlyData: null,
                physicalAddress: null,
                siteName: null,
                state: null,
                // CELL_MODEM_DC_PUBLIC
                blockTexting: 'NO',
                cancelDate: null,
                deactivationDate: null,
                pricePlanID: null,
                unrestrictedIP: 'NO',
                // CELL_MODEM_DC_PRIVATE
                ipClass: null,
                ipLocalAssigned: null,
                ipLocalStatic: null,
                wirelessNumber: null,
                // CELL_MODEM_DC_ALL
                accountNumber: null,
                activationDate: null,
                costCenter: null,
                coverage: null,
                dataPlan: null,
                deviceId: null,
                model: null,
                password: null,
                passwordDate: null,
                username: null,
                // CELL_MODEM_COMMON
                apn: null,
                imei: null,
                jobNumber: null,
                otherAddresses: '',
                passwordDateInitials:null,
                passwordUpdated: 'NO',
                phoneNumber: null,
                serialNumber: null,
                simsCard: null,
                // RADIO_ROUTER
                bacnetPort: 47808,
                gatewayAddress: '0.0.0.0',
                installDate: null,
                ipAddress: '0.0.0.0',
                networkNumber: 1,
                ssid: '',
                // COMMON
                commType: null,
                name: '',
                notes: true,
                credentials : [],
            },
            defaultCredential: {
                type: '',
                accountType: null,
                username: '',
                password: '',
                domain: '',
                notes: '',
                add: false,
                edit: false
            },
            dialogTitle: '',
            // this component
            valid: false,
            // credentials component
            credentialsValid: false,
            // comip component
            comipValid: false,
            validCommTypes: [],
            search: '',
            headers: [
                { text: 'Name', value: 'name', filterable: true},
                { text: 'Device Type', value: 'types', filterable: true, sortable: true},
                { text: 'Credentials', value: 'credentials', filterable: false },
                { text: 'Notes', value: 'notes', filterable: false },
            ],
            yesNo: [
                {name: 'Yes', value: 'YES'},
                {name: 'No', value: 'NO'}
            ],
            rules: {
                ipRules: {
                    optional: [
                        value => !value || ipRegex({exact: true}).test(value) || 'Invalid IP Address'
                    ],
                    always: [
                        value => ipRegex({exact: true}).test(value) || 'Invalid IP Address'
                    ],
                },
                required: value => !!value || 'Required.',
                notLegacy: value => this.validCommTypes.includes(value) || 'Must Choose a Device Type',
                uniqueName: value => {
                    let duplicate = null;
                    let result = true;
                    const v = value.trim().toLowerCase();
                    if(this.editedIndex === -1){
                        // new item
                        duplicate = this.localDevices.find(ld => ld.name.trim().toLowerCase() === v);
                    } else {
                        // edited item
                        duplicate = this.localDevices.find(ld => ld.name.trim().toLowerCase() === v && ld.id !== this.editedItem.id);
                    }
                    if(duplicate) {
                        result = 'Must be unique.';
                    }
                    return result;
                }
            }
        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Device' : 'Edit Device';
        },
        allValid () {
            if(this.dialog){
                if(['cell customer', 'cell dc private', 'cell dc public'].includes(this.editedItem.commType)){
                    return this.valid && this.credentialsValid && this.comipValid;
                }
                return this.valid && this.credentialsValid;
            }
            return false;
        }

    },
    watch: {
        dialog (val) {
            if(this.editedIndex < 0 && !this.editedItem.id){
                this.editedItem.id = dti.makeuuid();
                this.editedItem.notes = "";
            }
            if(val){
                this.$nextTick( () => {
                    this.valid = this.$refs.form && this.$refs.form.validate();
                });
                return true;
            }
            this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
        customerId: {
            immediate: true,
            handler (val){
                if(val) this.initialize();
            }
        },
        jiggle: {
            immediate: true,
            handler (val, prev) {
                if(val !== prev) this.initialize();
            }
        }
    },
    methods: {
        initialize () {
            console.group('commsMgr: initialize');
            // clone devices to prevent mutation of the prop
            this.localDevices = [];
            console.groupCollapsed('devices');
            this.devices
                .forEach((device) => {
                    console.log('device', device.id);
                    const clonedDevice = Object.assign({}, device);
                    clonedDevice.commTypeName = this.getCommTypeName(clonedDevice);
                    this.localDevices.push(clonedDevice);
                });
            console.groupEnd();

            // clone credentials to prevent mutation of the prop
            // will contain credentials for multiple machines/comms
            this.localCredentials = [];
            console.groupCollapsed('credentials');
            this.credentials
                .filter(c => c.type === this.localDeviceType)
                .forEach((credential, i) => {
                    console.log(i, 'credential', credential.id, credential.username, credential.customersCommId);
                    const clonedCredential = Object.assign({}, credential, {edit:false, add:false});
                    this.localCredentials.push(clonedCredential);
                });
            console.groupEnd();

            this.validCommTypes = this.commTypes.map((commType) => commType.value);

            this.editedItem = Object.assign({}, this.defaultDevice);

            console.groupEnd();
        },

        onCredValidChange (isValid) {
            this.credentialsValid = isValid;
        },

        onIpValidChange (isValid) {
            this.comipValid = isValid;
        },

        credentialsPerDevice (deviceId){
            return this.localCredentials.filter(i => i.customersCommId  === deviceId ).length;
        },

        onAddCredential (credential){
            credential.add = true;
            this.localCredentials.push(credential);
        },

        onDeleteCredential (item) {
            const index = this.localCredentials.findIndex(c => c.id === item.id);
            if(index > -1){
                this.localCredentials.splice(index,1);
            }
        },

        // fires when the credential is emitting updates
        onUpdateCredential (credential) {
            const index = this.localCredentials.findIndex(c => c.id === credential.id);
            if(index > -1){
                credential.edit = true;
                this.localCredentials[index] = credential;
            } else {
                this.onAddCredential(credential);
            }
        },

        /**
         * update the comm device with the new ip data
         * @param {object} payload
         * @param {string} payload.id - comm device id
         * @param {string} payload.otherAddresses - JSON array of other ip addresses
         */
        updateCommDeviceIpAddresses (payload) {
            this.editedItem.otherAddresses = payload.otherAddresses;
        },

        editItem (item) {
            console.log('commsMgr: editItem fired', item);
            this.editedIndex = this.localDevices.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.valid = false;
            this.dialog = true;
            this.$nextTick(() => {
                this.valid = this.$refs.form && this.$refs.form.validate();
            });
        },

        deleteItem (item) {
            console.log('commsMgr: deleteItem fired', item.id);
            this.editedIndex = this.localDevices.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        // runs when the user clicks the delete button in the dialog
        deleteItemConfirm () {
            console.group('commsMgr: deleteItemConfirm fired', this.editedItem.id, this.editedItem.type);
            this.localDevices.splice(this.editedIndex, 1);
            this.localCredentials = this.localCredentials.filter(c => c.customersCommId !== this.editedItem.id);
            this.updateComms(
                this.editedItem,
                this.localCredentials,
                'comm'
            );
            this.closeDelete();
            console.groupEnd();
        },

        getCommTypeName (item) {
            const found = this.commTypes.find((commType) => commType.value === item.commType);
            if(found) {
                return found.name;
            }
            return `Legacy ${item.commType}`;
        },

        close () {
            this.dialog = false;
            this.editedItem.commTypeName = this.getCommTypeName(this.editedItem);
            this.editedItem.customerId = this.customerId;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultDevice);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultDevice);
                this.editedIndex = -1;
            });
        },

        /**
         * Performs sends the devices and credentials back to customers
         */
        save () {
            if (this.editedIndex > -1) {
                this.editedItem.edit = true;
                Object.assign(this.localDevices[this.editedIndex], this.editedItem);
            } else {
                this.editedItem.add = true;
                this.localDevices.push(this.editedItem);
            }

            // update the customersComms (parent component)
            this.updateComms(
                this.editedItem,
                this.localCredentials,
                'comm'
            );
            this.close();
        },

        /**
         * Copies the specified text to the clipboard.
         *
         * @param {String} text
         */
        copyToClipboard (text) {
            navigator.clipboard.writeText(text);
        },

    }
};


</script>

<style lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>
