<template lang="pug">
.contactsManager
    v-card.ml-8(
        light
    )
        v-card-title.brand.white--text.py-2
            span Contacts
        v-card-subtitle
            v-text-field(
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                color="brand",
                clearable, clear-icon='clear'
            )
        v-card-text
            v-data-table(
                :headers="headers",
                :items="localContacts"
                :search="search",
                item-key="nameLast",
                sort-by="nameLast",
                dense,
                @click:row="editItem"
            )
                template(v-slot:item.notes="{ item }")
                    v-icon(v-if='item.notes') note
                //- Contact Editor
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='dialog'
                            max-width='900px'
                            light
                            width="50%"
                        )
                            template(v-slot:activator="{on, attrs}")
                                v-btn.brand.white--text(
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    ref="editButton"
                                ) New Contact
                            v-card
                                v-card-title.brand.white--text {{ formTitle }}
                                v-card-text
                                    v-container.pt-10
                                        v-form( ref="form" v-model="isValid" lazy-validation)
                                            v-row(dense)
                                                v-col(cols="5")
                                                    v-text-field(
                                                        label='First Name'
                                                        v-model.trim='editedItem.nameFirst'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color='brand'
                                                    )
                                                v-col(cols="6")
                                                    v-text-field(
                                                        label='Last Name'
                                                        v-model.trim='editedItem.nameLast'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color='brand'

                                                    )
                                            v-row(dense)
                                                v-col(cols="3")
                                                    v-select(
                                                        label="Contact Type"
                                                        v-model="editedItem.type"
                                                        :rules="[rules.required]"
                                                        :items="contactTypes"
                                                        item-text="name"
                                                        item-value="value"
                                                        outlined, dense, color="brand"
                                                    )

                                                v-col()
                                                    v-text-field(
                                                        label='Email'
                                                        v-model.trim='editedItem.email'
                                                        :rules="[rules.email]"
                                                        outlined, dense,  color='brand'
                                                        prepend-inner-icon="email"
                                                        append-icon='content_copy',
                                                        @click:append='copyToClipboard(editedItem.email)'
                                                    )

                                            v-row(dense)
                                                v-col(cols="6")
                                                    v-text-field(
                                                        label='Cell Number'
                                                        v-model.trim='editedItem.phoneCell'
                                                        :rules="[rules.phone]"
                                                        outlined, dense, color='brand'
                                                        prepend-inner-icon="smartphone"
                                                        append-icon='content_copy',
                                                        @click:append='copyToClipboard(editedItem.phoneCell)'
                                                    )
                                                v-col(cols="6")
                                                    v-text-field(
                                                        label='Phone Number'
                                                        v-model.trim='editedItem.phoneLand'
                                                        :rules="[rules.phone]"
                                                        outlined, dense, color='brand'
                                                        prepend-inner-icon="phone"
                                                        append-icon='content_copy',
                                                        @click:append='copyToClipboard(editedItem.phoneLand)'
                                                    )
                                            v-row(dense)
                                                v-col(cols="4")
                                                    v-select(
                                                        label='State'
                                                        v-model.trim='editedItem.state'
                                                        outlined, dense, color='brand'
                                                        :items="states"
                                                        item-text="name"
                                                        item-value="value"
                                                    )
                                                v-col()
                                                    v-text-field(
                                                        label='City'
                                                        v-model.trim='editedItem.city'
                                                        outlined, dense, color='brand'
                                                    )
                                            v-row(dense)
                                                v-col
                                                    v-text-field(
                                                        label='Street Address'
                                                        v-model.trim='editedItem.streetAddress'
                                                        outlined, dense, color='brand'
                                                    )
                                                v-col
                                                    v-text-field(
                                                        label='Zip Code'
                                                        v-model.trim='editedItem.postalCode'
                                                        outlined, dense, color='brand'
                                                    )
                                            v-row(dense)
                                                v-col
                                                    .text-h6 Notes
                                                    VueTrix.notesEditor(
                                                        v-model='editedItem.notes',
                                                        placeholder='Notes'
                                                    )
                                v-card-actions
                                    v-btn.actions.red.white--text(
                                        text
                                        :disabled="!isValid"
                                        @click="dialogDelete = true"
                                    ) Delete
                                    v-spacer
                                    v-btn.actions.orange.white--text(
                                        text
                                        @click="close"
                                    ) Cancel
                                    v-btn.actions.brand.white--text(
                                        text
                                        :disabled="!isValid"
                                        @click="save"
                                    ) Save

                        v-dialog(
                            v-model='dialogDelete'
                            max-width='500px'
                            light
                        )
                            v-card
                                v-card-title.brand.white--text Confirm Delete
                                v-card-text
                                    span.text-h6 Are you sure you want to delete this contact?
                                v-card-actions
                                    v-spacer
                                    v-btn.orange.white--text(
                                        text
                                        @click="closeDelete"
                                    ) Cancel
                                    v-btn.brand.white--text(
                                        text
                                        @click="deleteItemConfirm"
                                    ) OK
                template(v-slot:no-data)
                    v-btn(
                        color="primary"
                        @click="initialize"
                    ) Reset
</template>

<script>
import dti from 'dti';
import VueTrix from 'vue-trix';
import isEmail from 'isemail';
import parsePhoneNumber from 'libphonenumber-js';

export default {
    name: 'contactsManager',
    components: {
        VueTrix,
    },
    emits: ['deleteContact', 'updateContact', 'addContact'],
    inject: ['copyToClipboard'],
    props: {
        contacts: {
            type: Array,
            required: true
        },
        customerId: {
            type: Number,
            required: true
        },
        jiggle: {
            type: Boolean,
            default: false
        },
        states: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            isValid: false,
            localContacts: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {},
            dialogTitle: '',
            search: '',
            defaultItem: {
                type: 'it',
                nameLast: '',
                nameFirst: '',
                email: '',
                phoneCell: '',
                phoneLand: '',
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                notes: '',
                customerId: this.customerId,
                id: null
            },
            headers: [
                { text: 'Type', value: 'type'},
                { text: 'Last Name', value: 'nameLast'},
                { text: 'First Name', value: 'nameFirst'},
                { text: 'Cell #', value: 'phoneCell'},
                { text: 'Office #', value: 'phoneLand'},
                { text: 'Notes', value: 'notes'  }

            ],
            contactTypes: [
                {name: "IT", value: "it"},
                {name: "Manager", value: "manager"},
                {name: "Admin", value: "admin"},
                {name: "User", value: "user"},
                {name: "Other", value: "other"}
            ],

            rules: {
                required: value => !!value || 'Required',
                phone: value => {
                    let result = false;
                    if(value?.length === 0) {
                        result = true;
                    } else {
                        const phone = parsePhoneNumber(value, 'US');
                        if(phone) {
                            result = phone.isValid() || 'Invalid phone number';
                        } else {
                            result = 'Invalid phone number';
                        }
                        return result;
                    }
                    return result;
                },
                email: value => {
                    let result = false;
                    if(value?.length === 0) {
                        result = true;
                    } else {
                        result = isEmail.validate(value) || 'Invalid email address';
                    }
                    return result;
                },
                states: value => {
                    let result = false;
                    if(value?.length === 0) {
                        result = true;
                    } else {
                        result = value.length === 2 || 'Invalid state abbreviation';
                    }
                    return result;
                }
            },
        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Contact' : 'Edit Contact';
        }
    },
    watch: {
        customerId: {
            immediate: true,
            handler (val){
                if(val) this.initialize();
            }
        },
        dialog (val) {
            if(this.editedIndex < 0 && !this.editedItem.id){
                this.editedItem.id = dti.makeuuid();
                this.editedItem.notes = "";
                this.editedItem.type = this.contactTypes[0].value;
            }
            if(val){
                this.$nextTick( () => {
                    this.valid = this.$refs.form && this.$refs.form.validate();
                });
                return true;
            }
            this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
        jiggle: {
            immediate: true,
            handler (val, prev) {
                if(val !== prev) this.initialize();
            }
        }
    },
    methods: {
        initialize () {
            this.localContacts = [];
            this.contacts.forEach( contact => {
                const clonedContact = Object.assign({}, contact);
                this.localContacts.push(clonedContact);
            });
            this.editedItem = Object.assign({}, this.defaultItem);
        },

        updateContact (item) {
            this.$emit('updateContact', item);
        },

        editItem (item) {
            if(item){
                this.editedIndex = this.localContacts.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.valid = false;
                this.dialog = true;
                this.$nextTick(() => {
                    this.valid = this.$refs.form && this.$refs.form.validate();
                });
            }
        },

        deleteItem (item) {
            this.editedIndex = this.localContacts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        // runs when the user clicks the delete button in the dialog
        deleteItemConfirm () {
            this.localContacts.splice(this.editedIndex, 1);
            this.$emit('deleteContact', this.editedItem);

            this.closeDelete();
        },

        close () {
            this.dialog = false;
            this.editedItem.customerId = this.customerId;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        /**
         * Performs sends the contacts back to customers
         */
        save () {
            this.editedItem.phoneCell = this.editedItem.phoneCell ? parsePhoneNumber(this.editedItem.phoneCell, 'US').formatNational() : "";
            this.editedItem.phoneLand = this.editedItem.phoneLand ? parsePhoneNumber(this.editedItem.phoneLand, 'US').formatNational() : "";
            this.editedItem.email = this.editedItem.email ? this.editedItem.email.toLowerCase() : "";

            if (this.editedIndex > -1) {
                this.editedItem.edit = true;
                this.$emit('updateContact', this.editedItem);
            } else {
                this.editedItem.add = true;
                this.$emit('addContact', this.editedItem);
            }

            this.close();
        },

        /**
         * Update the device
         */
        onChange (contact) {
            this.isValid = this.$refs.form.validate();

            if(this.isValid){
                this.updateContact(contact);
            }
        },
    }
};
</script>

<style lang="css">
.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}

.actions.v-btn--disabled>span {
    color: #777 !important;
    background-color: #ddd !important;
}
</style>
