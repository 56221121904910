<template lang="pug">
.credsManager
    v-card.ml-8(light)
        v-card-title.brand.white--text.py-2
            span Credentials
            v-spacer
            span {{ sideLabel }}
        v-card-subtitle
            v-text-field(
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                color="brand",
                clearable, clear-icon='clear'
            )
        v-card-text
            v-data-table(
                :headers="headers"
                :items="localCredentials"
                :search="search"
                item-key="name"
                sort-by="type"
                dense
                @click:row='editItem'
            )
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='dialog'
                            max-width='900px'
                            light
                        )
                            template(v-slot:activator="{on, attrs}")
                                v-btn.brand.white--text(
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    ref="editButton"
                                ) New Credential
                            v-card
                                v-card-title.brand.white--text {{ formTitle }}
                                    v-btn.ml-3.white(
                                        :title='showPasswords ? "Hide sensitive information" : "Show sensitive information"', color='brand'
                                        icon, small,
                                        @click='showPasswords = !showPasswords',
                                    )
                                        v-icon {{ showPasswords ? 'visibility_off' : 'visibility' }}
                                v-card-text
                                    v-container.pt-10
                                        v-form( ref="form" v-model="isValid" lazy-validation)
                                            v-row
                                                span.black--text Note: Comm and Machine credentials are created from the Hardware tab
                                            v-row
                                                v-col
                                                    v-select(
                                                        v-if='editedItem.type === "forticloud" || editedItem.type === "cloud_backup"'
                                                        label="Credential Type"
                                                        v-model="editedItem.type"
                                                        :rules="[rules.required]"
                                                        :items="credentialChoices"
                                                        item-text="name"
                                                        item-value="value"
                                                        outlined, dense, color="brand"
                                                    )
                                                v-col
                                                    v-spacer
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label='Username'
                                                        v-model.trim='editedItem.username'
                                                        :rules="[rules.required]"
                                                        append-icon='content_copy',
                                                        @click:append='copyToClipboard(editedItem.username)'
                                                        outlined, dense,  color='brand'
                                                    )
                                                v-col
                                                    v-text-field(
                                                        label='Password'
                                                        v-model.trim='editedItem.password'
                                                        :rules="[rules.required]"
                                                        :type='showPasswords ? "text" : "password"'
                                                        append-icon='content_copy',
                                                        @click:append='copyToClipboard(editedItem.password)'
                                                        outlined, dense,  color='brand'
                                                    )

                                            v-row
                                                v-col
                                                    .text-h6 Notes
                                                    VueTrix.notesEditor(
                                                        v-model='editedItem.notes',
                                                        placeholder='Notes'
                                                    )

                                v-card-actions
                                    v-btn.actions.red.white--text(
                                        text
                                        :disabled="!isValid"
                                        @click="dialogDelete = true"
                                    ) Delete
                                    v-spacer
                                    v-btn.actions.orange.white--text(
                                        text
                                        @click="close"
                                    ) Cancel
                                    v-btn.actions.brand.white--text(
                                        text
                                        :disabled="!isValid"
                                        @click="save"
                                    ) Save
                        v-dialog(
                            v-model='dialogDelete'
                            max-width='500px'
                            light
                        )
                            v-card
                                v-card-title.brand.white--text Confirm Delete
                                v-card-text
                                    span.text-h6 Are you sure you want to delete this device?
                                v-card-actions
                                    v-spacer
                                    v-btn.orange.white--text(
                                        text
                                        @click="closeDelete"
                                    ) Cancel
                                    v-btn.brand.white--text(
                                        text
                                        @click="deleteItemConfirm"
                                    ) OK

                template(v-slot:no-data)
                    v-btn(
                        color="primary"
                        @click="initialize"
                    ) Reset
</template>

<script>
import dti from 'dti';
import VueTrix from 'vue-trix';

export default {
    name: 'credentialsManager',
    components: {
        VueTrix
    },
    emits: ['deleteCredential', 'updateCredential', 'addCredential'],
    inject: ['copyToClipboard'],
    props: {
        credentials: {
            type: Array,
            required: true
        },
        customerId: {
            type: Number,
            required: true
        },
        credentialTypes: {
            type: Array,
            required: true
        },
        commDevices: {
            type: Array,
            required: true,
            default () {
                return [];
            }
        },
        machines: {
            type: Array,
            required: true,
            default () {
                return [];
            }
        },
        jiggle: {
            type: Boolean,
            default: false
        },
        rightLabel: {
            type: String,
            default: 'Hardware Excluded',
            required: false
        },
        filterTypes: {
            type: Array,
            required: false,
            default: () => {
                return [
                    'machine', 'cloud_backup', 'vpn', 'forticloud'
                ];
            }
        }
    },
    data () {
        return {
            sideLabel: '',
            showPasswords: false,
            isValid: false,
            localCredentials: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
            },
            dialogTitle: '',
            search: '',
            defaultItem: {
                type: '',
                accountType: null,
                username: '',
                password: '',
                domain: '',
                notes: '',
                id: null,
                customersCommId: null,
                customersMachineId: null,
                add: false,
                edit: false
            },
            headers: [
                { text: 'Type', value: 'type', filterable: true},
                { text: 'Username', value: 'username', filterable: true},
                { text: 'Machine', value: 'machineName', filterable: true, sortable: true},
                { text: 'Comm', value: 'deviceName', filterable: true, sortable: true},
            ],
            accountTypes: [
                {
                    name: 'Local Account',
                    value: 'local'
                },
                {
                    name: 'Domain Account',
                    value: 'domain'
                },
            ],
            credentialChoices: [
                {
                    name: 'Cloud backup',
                    value: 'cloud_backup'
                },
                {
                    name: 'Forticloud',
                    value: 'forticloud'
                }
            ],
            rules: {
                required: value => !!value || 'Required.',
            },
        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Credential' : 'Edit Credential';
        },
    },
    watch: {
        customerId: {
            immediate: true,
            handler (val){
                if(val) this.initialize();
            }
        },
        dialog (val) {
            if(this.editedIndex < 0 && !this.editedItem.id){
                this.editedItem.id = dti.makeuuid();
                this.editedItem.notes = "";
                this.editedItem.type = this.credentialTypes[0].value;
            }
            if(val){
                this.$nextTick( () => {
                    this.valid = this.$refs.form && this.$refs.form.validate();
                });
                return true;
            }
            this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
        jiggle: {
            immediate: true,
            handler (val, prev) {
                if(val !== prev) this.initialize();
            }
        }
    },
    methods: {
        initialize () {
            console.group('credsManager: initialize');
            this.sideLabel = this.rightLabel;
            this.localCredentials = [];
            this.credentials
                .forEach(credential => {
                    const clonedCredential = Object.assign({}, credential);
                    if (credential.type === 'comm') {
                        const commDevice = this.commDevices.find(device => device.id === credential.customersCommId);
                        if (commDevice) {
                            clonedCredential.deviceName = commDevice.name;
                        }
                    }
                    if( credential.type === 'machine' ) {
                        const machine = this.machines.find( machine => machine.id === credential.customersMachineId );
                        if( machine ) {
                            clonedCredential.machineName = machine.machineName;
                        }
                    }
                    this.localCredentials.push(clonedCredential);
                });

            this.editedItem = Object.assign({}, this.defaultItem);
            console.groupEnd();
        },

        updateCredential (item) {
            const foundIndex = this.localCredentials.findIndex(cred => cred.id === item.id);
            this.$set(this.localCredentials, foundIndex, item);
            this.$emit('updateCredential', item);
        },

        editItem (item) {
            this.editedIndex = this.localCredentials.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.valid = false;
            this.dialog = true;
            this.$nextTick(() => {
                this.valid = this.$refs.form && this.$refs.form.validate();
            });
        },

        deleteItem (item) {
            console.log('deleteItem fired', item.id);
            this.editedIndex = this.localCredentials.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        // runs when the user clicks the delete button in the dialog
        deleteItemConfirm () {
            console.log('deleteItemConfirm fired');
            this.localCredentials.splice(this.editedIndex, 1);
            this.$emit('deleteCredential', this.editedItem);

            this.closeDelete();
        },

        close () {
            this.dialog = false;
            this.editedItem.customerId = this.customerId;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        /**
         * Performs sends the devices and credentials back to customers
         */
        save () {
            console.log('credsMgr: save fired', this.editedItem.id, this.editedItem.type, this.editedIndex);
            if (this.editedIndex > -1) {
                this.editedItem.edit = true;
                Object.assign(this.localCredentials[this.editedIndex], this.editedItem);
                this.$emit('updateCredential', this.editedItem);
            } else {
                this.editedItem.add = true;
                this.localCredentials.push(this.editedItem);
                this.$emit('addCredential', this.editedItem);
            }

            this.close();
        },

        /**
         * Update the device
         */
        onChange (credential) {
            this.isValid = this.$refs.form.validate();

            if(this.isValid){
                this.updateCredential(credential);
            }
        },
    }
};


</script>


<style lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>
