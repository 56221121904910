<router>
{
    name: 'Customers',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            DevopsCustomers

</template>
<script>

import DevopsCustomers from '@/components/devops/customers';
export default {
    meta: {
        role: 'devopsupdate'
    },
    components: {
        DevopsCustomers,
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.devopscustomersvm = this;
    }
};
</script>

<style scoped>
</style>
