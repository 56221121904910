<template lang='pug'>
.ipAddressInput.d-flex
    v-text-field(v-for='(segment, index) in segments', type='number', ref=`segmentInputs`, v-model.number='segments[index]', dense, outlined, color='brand', :label='index === 0 ? label : ""', @focus='highlightAllText', @keydown='handleKeyDown($event, index)', @input='handleInput($event, index)', :key='index', :min='min', :max='max', hide-details)
        v-btn test
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        label: {
            type: String,
            default () {
                return 'IP';
            }
        }
    },
    data () {
        return {
            segments: ['000', '000', '000', '000'],
            min: 0,
            max: 255,
            numbers: ['1','2','3','4','5','6','7','8','9','0'],
            allowedKeys: ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '.', 'Tab', 'Shift', 'Backspace']
        };
    },
    computed: {
        /**
         * Returns all 4 segments joined by a "."
         */
        ipAddress () {
            return this.segments.join('.');
        },
    },
    methods: {
        /**
         * Keydown event handler. Only allows numbers to be entered into the text field. Will move focus to the next element when:
         *  - the tab key is pressed
         *  - the "." key is pressed
         *
         * @param {Object} event
         * @param {Number} segmentIndex
         */
        handleKeyDown (event, segmentIndex) {
            const isNumberInput = this.numbers.includes(event.key);
            if (!isNumberInput) {
                if (!this.allowedKeys.includes(event.key)) {
                    event.preventDefault();
                    return;
                }
                switch (event.key) {
                case '.':
                    event.preventDefault();
                    if (segmentIndex < 3 ) {
                        this.goToNextInput(segmentIndex);
                    }
                    break;
                default:
                    break;
                }
            } else {
                const selection = document.getSelection();
                const isSelected = selection && selection.focusNode === event.target;
                if (event.target.value.length === 3 && segmentIndex === 3 && !isSelected) {
                    event.preventDefault();
                }
            }
        },
        /**
         * Input event handler. Will move the focus to the next segment if a 3rd value is entered.
         *
         * @param {String} segmentValue
         * @param {Number} segmentIndex
         */
        handleInput (segmentValue, segmentIndex) {
            if (segmentValue.length === 3) {
                this.goToNextInput(segmentIndex);
            }
        },
        /**
         * Moves the focus to the next element unless the current element is the 3rd (last) one.
         *
         * @param {Number} currentIndex - Index of the current segment.
         */
        goToNextInput (currentIndex) {
            if (currentIndex == 3) {
                return;
            }
            const nextInput = this.$refs.segmentInputs[currentIndex + 1];
            nextInput.focus();
            nextInput.$el.querySelector('input').select();
        },
        /**
         * Highlights all text of the input.
         *
         * @param {Object} event
         */
        highlightAllText (event) {
            event.target.select();
        }
    },
    watch: {
        /**
         * Emits the "input" event when the ip address changes.
         */
        ipAddress () {
            this.$emit('input', this.ipAddress);
        }
    },
    /**
     * On startup, the value of the segments are set _if_ the provided string is a "valid" IP.
     */
    mounted () {
        const segments = this.value.split('.');
        if (segments.length === 4 ) {
            this.segments = segments;
        }
    }
};
</script>
